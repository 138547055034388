@import "styles/mixins.scss";
@import "styles/variables.scss";

.root {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 100vh;
  padding: 0 25px;
  background-color: var(--color-juliet);
}

.header {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 23px 0;
}

.content {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.title {
  width: 100%;
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: 300;
  line-height: 1;
  color: var(--color-bravo);
  text-align: center;

  @media screen and (max-width: $breakpoint-md) {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 300;
  }
}

.description {
  width: 100%;
  font-size: 14px;
  color: var(--color-charlie);
  text-align: center;

  @media screen and (max-width: $breakpoint-md) {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.43;
  }
}

.go-back {
  color: var(--color-bravo);

  &:hover {
    text-decoration: none;
  }

  @media screen and (max-width: $breakpoint-md) {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.43;
  }
}
